import PropTypes from 'prop-types'
import React from 'react'

export const FiltersRow = ({ children }) => (
  <section className='filters-container__row row'>{children}</section>
)

FiltersRow.propTypes = {
  children: PropTypes.node,
}
