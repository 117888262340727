import React from 'react'
import { ReportsFilters } from './Filters'
import Widgets from './Widgets'

class ReportsPage extends React.Component<any, any> {
  render() {
    return (
      <div className='reports'>
        <ReportsFilters />
        <Widgets />
      </div>
    )
  }
}

export { ReportsPage }
export default { ReportsPage }
