import React, { Component, Fragment } from 'react'
import Map from './Map'
import Slider from './Slider'
import { rangeMap } from '../../store/app/range-map'
import { compose } from 'redux'
import Button from '../ui/ButtonComponent'
import { STEP_MAP, STEP_SEARCHING } from '../../store/app/hotels-booking'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { hotelsBooking } from '../../store/app/hotels-booking/providers/hotelsBooking'
import { trans } from '../../trans'
import { get } from 'lodash'
import SingleOffer from './SingleOffer'
import { Map as ImmutableMap } from 'immutable'
import { Modal as OfferListDialog } from './OfferList/Modal'
import Reservation from './Reservation/Reservation'

export const MEAL_TYPES = {
  RO: 'hotels-booking.meal-RO',
  BB: 'hotels-booking.meal-BB',
  HB: 'hotels-booking.meal-HB',
  FB: 'hotels-booking.meal-FB',
  AI: 'hotels-booking.meal-AI',
  BF: 'hotels-booking.meal-BF',
}

export const ROOM_TYPES = {
  SGL: 'hotels-booking.room-SGL',
  DBL: 'hotels-booking.room-DBL',
}

class HotelsBooking extends Component<any, any> {
  closeResultsList = () => {
    const { hotelsBooking, closeList } = this.props
    hotelsBooking.actions.setFilter('others', '')
    hotelsBooking.actions.closeResultsList()
    closeList()
  }

  backToMap = (e) => {
    const { hotelsBooking } = this.props

    e.stopPropagation()

    hotelsBooking.actions.backToMap()
  }

  selectOfferOption = (offer, option) => {
    const { hotelsBooking } = this.props

    hotelsBooking.actions.selectOfferOption(hotelsBooking.request, {
      search_uuid: hotelsBooking.selectors.uuid,
      offer,
      option,
      request_element: hotelsBooking.element,
    })
  }

  loader = () => {
    const { hotelsBooking } = this.props

    const loader = <Loader />

    if (hotelsBooking.selectors.step === STEP_MAP) {
      return null
    }

    if (!hotelsBooking.selectors.initialized) {
      return loader
    }

    if (hotelsBooking.selectors.step === STEP_SEARCHING) {
      return loader
    }

    return null
  }

  openDialog = () => {
    const { hotelsBooking } = this.props
    hotelsBooking.actions.selectOffer(undefined)
    hotelsBooking.selectors.status = 'pending'

    if (hotelsBooking.element.draft) {
      hotelsBooking.actions.setFilter('standard', 0)
    }
  }

  render() {
    const { rangeMap, hotelsBooking, reservation, isListOpen } = this.props

    if (!hotelsBooking.selectors.isSearchEnabled) {
      return null
    }

    if (!hotelsBooking.selectors.initialized) {
      return <Loader />
    }

    if (hotelsBooking.selectors.isOptionSelected) {
      if (reservation) {
        return <Reservation hotelsBooking={hotelsBooking} rangeMap={rangeMap} />
      }

      return (
        <SingleOffer
          hotelsBooking={hotelsBooking}
          reservation={reservation}
          onClose={this.closeResultsList}
        />
      )
    }

    return (
      <Fragment>
        {hotelsBooking.selectors.isSearching && <Loader />}

        <div className='accommodation__offers'>
          {hotelsBooking.selectors.initialized && rangeMap.selectors.initialized && (
            <Fragment>
              <Map
                radius={rangeMap.selectors.radius}
                onChange={rangeMap.actions.setRange}
                coordinates={rangeMap.selectors.coordinates}
              />
              <Slider onChange={rangeMap.actions.setRange} value={rangeMap.selectors.radius} />
            </Fragment>
          )}
        </div>

        <OfferListDialog
          isOpen={hotelsBooking.selectors.isResultsListOpen}
          onClose={this.closeResultsList}
          hotelsBooking={hotelsBooking}
          onOpen={this.openDialog}
        />
      </Fragment>
    )
  }
}

const withRangeMap = rangeMap(true)
const withHotelsBooking = hotelsBooking(true)

HotelsBooking = compose(withHotelsBooking, withRangeMap)(HotelsBooking)

export { HotelsBooking }
export default HotelsBooking
