import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export const FiltersCol = ({ children, xs, sm, md, lg, title, className }) => {
  const classes = classnames(
    {
      'filters-container__col': true,
      filter: true,
      [`xs-${xs}`]: !!xs,
      [`sm-${sm}`]: !!sm,
      [`md-${md}`]: !!md,
      [`lg-${lg}`]: !!lg,
    },
    className,
  )

  let _title = null
  if (title && title.length) {
    _title = <span className='filter__title'>{title}</span>
  }

  return (
    <section className={classes}>
      {_title}
      {children}
    </section>
  )
}

FiltersCol.propTypes = {
  children: PropTypes.node,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
}
