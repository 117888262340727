import React, { Component, Fragment } from 'react'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { connect } from '../../../containers/FlightsBooking'
import SelectedOffer from './SelectedOffer'
import { SearchForm } from './SearchForm'
import { RequestDocuments } from '../../RequestPageCommon/RequestDocuments'
import { get } from 'lodash'
import DocumentsAndBackToSearching from './DocumentsAndBackToSearching'
import {
  BOOKING_CANCELLATION_FAILED,
  BOOKING_CANCELLED,
  BOOKING_PENDING_CANCELLATION,
  BOOKING_SUCCESS,
} from '../../../store/app/flights-booking'

class ReservationContentComponent extends Component<any, any> {
  componentDidMount = () => {
    const {
      flights: {
        actions: { fetch, setQuery },
      },
      context: { element: plane },
    } = this.props

    fetch()
    setQuery({
      flight_from: plane.flight_from,
      flight_to: plane.flight_to,
      flight_class: plane.flight_class,
      arrival_at: plane.arrival_at,
      return_at: plane.return_at,
      round_trip: plane.round_trip,
      direct_only: plane.direct_only,
      restrict_to_fba: plane.restrict_to_fba,
      request_travelers: plane.request_travelers,
    })
  }

  isLoading = () => {
    const {
      flights: {
        selectors: { isLoading, isBlocked },
      },
    } = this.props

    return isLoading || isBlocked
  }

  renderDocuments = (fragment = false) => {
    const {
      context: { element, request },
    } = this.props

    return (
      <RequestDocuments
        element={element}
        documents={get(element, 'documents', [])}
        request={request}
        listName={`${element.type}-${element.id}`}
        fragment={fragment}
      />
    )
  }

  renderForm = () => {
    return <SearchForm />
  }

  renderSelectedOffer = () => {
    return <SelectedOffer />
  }

  render() {
    const {
      flights,
      context: { request, element },
    } = this.props
    const {
      flights: {
        selectors: { selectedBack, selectedThere, query },
      },
    } = this.props
    const offer = selectedBack ? selectedBack : selectedThere
    const status = get(offer, 'booking')

    const isInCancellation =
      status === BOOKING_CANCELLED ||
      status === BOOKING_PENDING_CANCELLATION ||
      status === BOOKING_CANCELLATION_FAILED

    const showSingleOffer =
      (!element.round_trip && selectedThere) || (element.round_trip && selectedBack)

    if (isInCancellation) {
      return this.renderDocuments()
    }

    if (request.abilities.view && !request.abilities.edit && !request.abilities.bookOffers) {
      if (!flights.selectors.isSearchEnabled) {
        return this.renderDocuments()
      }

      return (
        <Fragment>
          {!this.isLoading() && this.renderSelectedOffer()}
          {this.isLoading() && <Loader />}
        </Fragment>
      )
    }

    if (!get(request, 'abilities.bookOffers', false)) {
      return this.renderDocuments()
    }

    if (!flights.selectors.isSearchEnabled) {
      return <DocumentsAndBackToSearching />
    }

    if (!flights.selectors.initialized) {
      return <Loader />
    }

    const isBooked = get(offer, 'options.0.booking', null) === BOOKING_SUCCESS

    if (isBooked) {
      return this.renderDocuments()
    }

    return (
      <Fragment>
        {showSingleOffer ? this.renderSelectedOffer() : this.renderForm()}

        {this.isLoading() && <Loader />}
      </Fragment>
    )
  }
}

const ReservationContent = connect(ReservationContentComponent)

export { ReservationContent }
export default ReservationContent
