import React, { useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import { useHistory, useParams } from 'react-router-dom'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { CellClickedEvent } from 'ag-grid-community'
import { IAccountStatement } from '../../types/account'
import { Row } from '../ui/AccordionTable'
import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import APIClient from '../../services/APIClient'
import { useSelector } from 'react-redux'
import { getMyCardTenantId } from '../../store/app/user-profile/userProfileSlice'

enum ActionType {
  EXPORT_PDF = 'application/pdf',
  EXPORT_MT940 = 'application/x-mt940',
}

function AccountsStatementsPageTable({ data, paginator, setPage }) {
  const [selected, setSelected] = useState<IAccountStatement[]>([])
  const [action, setAction] = useState<ActionType>()
  const gridOptions = useGridOptions()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const tenant = useSelector(getMyCardTenantId)

  const applyAction = useCallback(() => {
    return APIClient.exportStatements(
      selected.map((statement) => statement.id),
      action,
      tenant,
    )
  }, [action])

  const onCellClicked = useCallback((event: CellClickedEvent<IAccountStatement>) => {
    if (event.column.getId() === 'id') {
      event.node.setSelected(!event.node.isSelected())

      return
    }

    history.push(`/bank-account/${params.id}/statements/${event.data.id}/entries`)
  }, [])

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
          onSelectionChanged={(e) => {
            setSelected(e.api.getSelectedRows())
          }}
        />
      </div>

      {selected.length > 0 && (
        <Row className='notification-bar'>
          <Col sm={12} is_pull_end>
            <div className='notification-bar__row'>
              <ExpenseRequestActions hideNotApplicable>
                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    value={action}
                    label={trans('global.export_to', { format: 'MT940' }) + ` (${selected.length})`}
                    onClick={() => {
                      setAction(ActionType.EXPORT_MT940)
                    }}
                  />
                </div>
                <div className='react-select__option'>
                  <Radio
                    name='status'
                    disabled={!selected.length}
                    value={action}
                    label={trans('global.export_to', { format: 'PDF' }) + ` (${selected.length})`}
                    onClick={() => {
                      setAction(ActionType.EXPORT_PDF)
                    }}
                  />
                </div>
              </ExpenseRequestActions>

              <Button primary xs disabled={!selected.length || !action} onClick={applyAction}>
                {trans('global.perform')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  )
}

export default AccountsStatementsPageTable
