import { DefaultLayout } from './layouts/DefaultLayout'
import { TwoColumnLayout } from './layouts/TwoColumnLayout'
import DashboardPage from './components/DashboardPage'
import { StyleGuide } from './components/ui/StyleGuide'
import { ExpenseRequestPage } from './components/ExpenseRequestPage'
import { clone, find, forOwn, replace } from 'lodash'
import DocumentPage from './containers/DocumentPage'
import { DocumentsListPage } from './components/DocumentsListPage'
import { ExpenseRequestsListPage } from './components/ExpenseRequestsListPage'
import { AccountDocumentPage } from './components/AccountDocumentPage'
import { RequestMileageAllowancePage } from './components/RequestMileageAllowancePage'
import { SettlementRequestsListPage } from './components/SettlementRequestsListPage'
import { TripRequestsListPage } from './components/TripRequestsListPage'
import { ReportsPage } from './components/ReportsPage'
import { UserProfilePage } from './components/UserProfilePage'
import { TripRequestPage } from './components/TripRequestPage'
import { TripRequestSummaryPage } from './components/TripRequestSummaryPage'
import { InstallmentsListPage } from './components/InstallmentsListPage'
import InstanceSettingsPage from './components/InstanceSettingsPage'
import UsersPage from './components/SettingsPage/UsersPage/Page'
import ExternalUserProfilePage from './components/UserProfilePage/ExternalUserProfilePage'
import { AgentTripRequestsListPage } from './components/TripRequestsListPage/AgentTripRequestsListPage'
import {
  AcceptRequestPage,
  RejectRequestPage,
  ReturnToImprovementRequestPage,
} from './components/ReuqestLinkPages'
import CorporateCards from './components/InstanceSettingsPage/CorporateCards'
import UserCreatePage from './components/SettingsPage/UsersCreatePage/UserCreatePage'
import CompanyPage from './components/SettingsPage/CompanyPage/Page'
import TripRequestCreatePage from './components/TripRequestPage/TripRequestCreatePage'
import PaymentsPage from './components/PaymentsPage'
import MyCardPage from './components/MyCardPage'
import StatementsPage from './components/StatementsPage/StatementsPage'
import StatementPage from './components/StatementPage'
import AccountsPage from './components/AccountsPage'
import { KontomatikPage } from './components/Kontomatik'
import AccountStatementsPage from './components/AccountStatementsPage'
import AccountPaymentsPage from './components/AccountPaymentsPage'

export const routes = {
  main: [
    {
      name: 'dashboard',
      exact: true,
      path: '/dashboard',
      component: DashboardPage,
      layout: DefaultLayout,
      label: 'global.dashboard',
      showInMenu: true,
      icon: 'dashboard',
      abilities: {
        ability: ['isAgent'],
        operator: 'not',
      },
    },
    // {
    //   name: 'kontomatik',
    //   exact: true,
    //   path: '/kontomatik',
    //   component: KontomatikPage,
    //   layout: DefaultLayout,
    //   label: 'Kontomatik',
    //   showInMenu: true,
    //   icon: 'icon-card-outlined',
    //   abilities: {
    //     ability: ['isAgent', 'isAdmin', 'isSuperAdmin'],
    //     operator: 'or',
    //   },
    // },
    {
      name: 'tripRequestsList',
      exact: true,
      path: '/requests/trip',
      component: TripRequestsListPage,
      layout: DefaultLayout,
      label: 'main-menu.list-trip-request',
      showInMenu: true,
      icon: 'suitcase',
      abilities: {
        ability: ['showTrip'],
      },
    },
    {
      name: 'tripRequestAdd',
      exact: true,
      path: '/requests/trip/add',
      component: TripRequestCreatePage,
      layout: TwoColumnLayout,
      label: 'main-menu.add-trip-request',
      showInMenu: true,
      parent: 'tripRequestsList',
      icon: 'plus_outline',
      abilities: {
        ability: ['showRegularTrip'],
      },
    },
    {
      name: 'acceptRequest',
      exact: true,
      path: '/request/:slug/accept',
      component: AcceptRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'rejectRequest',
      exact: true,
      path: '/request/:slug/reject',
      component: RejectRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'returnRequestToImprovement',
      exact: true,
      path: '/request/:slug/return-to-improvement',
      component: ReturnToImprovementRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'tripRequestShow',
      exact: true,
      path: '/requests/trip/:id',
      component: TripRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'tripRequestSummaryShow',
      exact: true,
      path: '/requests/:type/:id/summary',
      component: TripRequestSummaryPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'summaryDocument',
      exact: true,
      path: '/requests/:type/:request/summary/documents/:id',
      component: DocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'expenseRequestsList',
      exact: true,
      path: '/requests/expense',
      component: ExpenseRequestsListPage,
      layout: DefaultLayout,
      label: 'main-menu.list-trip-expense',
      showInMenu: true,
      icon: 'wallet',
      abilities: {
        ability: ['showExpense'],
        operator: 'or',
      },
    },
    {
      name: 'settlementRequestsList',
      exact: true,
      path: '/claims',
      component: SettlementRequestsListPage,
      layout: DefaultLayout,
      label: 'main-menu.list-settlements',
      showInMenu: true,
      icon: 'check',
      abilities: {
        ability: ['isAccountant', 'showSettlements'],
        operator: 'and',
      },
    },
    {
      name: 'my-card',
      exact: true,
      path: '/my-card',
      component: MyCardPage,
      layout: DefaultLayout,
      showInMenu: true,
      label: 'main-menu.my-card',
      icon: 'icon-card-outlined',
      abilities: {
        ability: ['mycardPayments', 'mycardStatements'],
        operator: 'or',
      },
    },
    {
      name: 'my-card-payments',
      exact: true,
      path: '/my-card/payments',
      component: PaymentsPage,
      layout: DefaultLayout,
      parent: 'my-card',
      showInMenu: true,
      label: 'main-menu.my-card-payments',
      icon: 'icon-card-outlined',
      hasLink: 'transactions',
    },
    {
      name: 'my-card-statements',
      exact: false,
      path: '/my-card/statements',
      component: StatementsPage,
      layout: DefaultLayout,
      parent: 'my-card',
      showInMenu: true,
      label: 'main-menu.my-card-statements',
      icon: 'icon-document-ok',
      abilities: {
        ability: ['mycardStatements'],
        operator: 'or',
      },
    },
    {
      name: 'my-card-accounts',
      exact: true,
      path: '/bank-account',
      component: AccountsPage,
      layout: DefaultLayout,
      showInMenu: true,
      label: 'main-menu.my-card-accounts',
      icon: 'icon-bank',
      hasLink: 'accounts',
    },
    {
      name: 'my-card-accounts-statements',
      exact: true,
      path: '/bank-account/:id/statements',
      component: AccountStatementsPage,
      parent: 'my-card-accounts',
      layout: DefaultLayout,
      showInMenu: false,
      hasLink: 'accounts',
    },
    {
      name: 'my-card-accounts-entries',
      exact: true,
      path: '/bank-account/:id/statements/:statementId/entries',
      component: AccountPaymentsPage,
      parent: 'my-card-accounts',
      layout: DefaultLayout,
      showInMenu: false,
      hasLink: 'accounts',
    },
    {
      name: 'my-card-statement',
      exact: true,
      path: '/my-card/statement/:id',
      component: StatementPage,
      layout: DefaultLayout,
      parent: 'my-card',
      showInMenu: false,
      abilities: {
        ability: ['mycardStatements'],
        operator: 'or',
      },
    },
    {
      name: 'showTripSettlement',
      exact: true,
      path: '/claims/trip/:id',
      component: TripRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'showExpenseSettlement',
      exact: true,
      path: '/claims/expense/:id',
      component: ExpenseRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'settlementDocument',
      exact: true,
      path: '/claims/:type/:request/documents/:id',
      component: DocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'settlementAccountDocument',
      exact: true,
      path: '/claims/:type/:request/documents/:id/account',
      component: AccountDocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'settlementRequestMileageAllowance',
      path: '/claims/:type/:id/cover',
      component: RequestMileageAllowancePage,
      layout: DefaultLayout,
      showInMenu: false,
      exact: true,
    },
    {
      name: 'expenseRequestAdd',
      exact: true,
      path: '/requests/expense/add-regular',
      component: ExpenseRequestPage,
      layout: TwoColumnLayout,
      label: 'main-menu.add-expense-request',
      showInMenu: true,
      icon: 'plus_outline',
      parent: 'expenseRequestsList',
      abilities: {
        ability: ['showRegularExpense'],
      },
    },
    {
      name: 'periodicExpenseRequestAdd',
      exact: true,
      path: '/requests/expense/add-periodic',
      component: ExpenseRequestPage,
      layout: TwoColumnLayout,
      label: 'main-menu.add-periodic-expense-request',
      showInMenu: true,
      icon: 'plus_outline',
      parent: 'expenseRequestsList',
      abilities: {
        ability: ['showPeriodicExpense'],
      },
    },
    {
      name: 'expenseRequestShow',
      exact: true,
      path: '/requests/expense/:id',
      component: ExpenseRequestPage,
      layout: TwoColumnLayout,
      showInMenu: false,
    },
    {
      name: 'installmentsListPage',
      exact: true,
      path: '/advances',
      component: InstallmentsListPage,
      layout: DefaultLayout,
      label: 'main-menu.installments',
      showInMenu: true,
      icon: 'wallet',
      abilities: {
        ability: ['isAccountant', 'showInstallments'],
        operator: 'and',
      },
    },
    {
      name: 'styleguide',
      exact: true,
      path: '/styleguide',
      component: StyleGuide,
      layout: DefaultLayout,
      label: 'main-menu.styleguide',
      showInMenu: false,
      icon: 'star',
    },
    {
      name: 'requestAccountDocument',
      exact: true,
      path: '/requests/:type/:request/documents/:id/account',
      component: AccountDocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'RequestMileageAllowance',
      path: '/requests/:type/:id/cover',
      component: RequestMileageAllowancePage,
      layout: DefaultLayout,
      showInMenu: false,
      exact: true,
    },
    {
      name: 'RequestSummaryMileageAllowance',
      path: '/requests/:type/:id/summary/cover',
      component: RequestMileageAllowancePage,
      layout: DefaultLayout,
      showInMenu: false,
      exact: true,
    },
    {
      name: 'document',
      exact: true,
      path: '/documents/:id',
      component: DocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'AccountDocument',
      exact: true,
      path: '/documents/:id/account',
      component: AccountDocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'document',
      exact: true,
      path: '/documents/:id',
      component: DocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'requestDocument',
      exact: true,
      path: '/requests/:type/:request/documents/:id',
      component: DocumentPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'reports',
      exact: true,
      path: '/reports',
      component: ({ history }) => history.push('/reports/documents') || null,
      layout: DefaultLayout,
      showInMenu: true,
      label: 'main-menu.reports',
      icon: 'reports',
      abilities: {
        ability: ['showReports'],
        operator: 'or',
      },
    },
    {
      name: 'allRequests',
      exact: true,
      path: '/reports/documents',
      component: DocumentsListPage,
      layout: DefaultLayout,
      label: 'main-menu.transactions',
      showInMenu: true,
      parent: 'reports',
      icon: 'transactions',
      abilities: {
        ability: ['showTransaction'],
        operator: 'or',
      },
    },
    {
      name: 'cockpit',
      exact: true,
      path: '/reports/cockpit',
      component: ReportsPage,
      layout: DefaultLayout,
      parent: 'reports',
      showInMenu: true,
      label: 'main-menu.cockpit',
      icon: 'heart-rate-search',
      abilities: {
        ability: ['showCockpit'],
        operator: 'or',
      },
    },
    {
      name: 'settings',
      exact: false,
      path: '/user/profile',
      component: UserProfilePage,
      layout: DefaultLayout,
      showInMenu: false,
      label: 'main-menu.instance-settings',
      icon: 'settings',
    },
    {
      exact: true,
      path: '/',
      component: DashboardPage,
      layout: DefaultLayout,
      showInMenu: false,
    },
    {
      name: 'external-user',
      exact: false,
      path: '/settings/instance/user/:slug',
      component: ExternalUserProfilePage,
      layout: DefaultLayout,
      showInMenu: false,
      abilities: {
        ability: ['isInstanceAdmin'],
      },
    },
    {
      name: 'users',
      exact: true,
      path: '/settings/instance/users',
      component: UsersPage,
      layout: DefaultLayout,
      label: 'main-menu.users',
      parent: 'instance-settings',
      showInMenu: true,
      icon: 'users',
      abilities: {
        ability: ['isInstanceAdmin', 'instanceSettingsManageUsers'],
        operator: 'or',
      },
    },
    {
      name: 'createUser',
      exact: true,
      path: '/settings/instance/users/create',
      component: UserCreatePage,
      layout: DefaultLayout,
      showInMenu: false,
      abilities: {
        ability: ['isInstanceAdmin'],
      },
    },
    {
      name: 'company',
      exact: false,
      path: '/settings/instance/company',
      component: CompanyPage,
      layout: DefaultLayout,
      label: 'global.company',
      parent: 'instance-settings',
      showInMenu: true,
      icon: 'company-settings',
      abilities: {
        ability: ['isInstanceAdmin', 'dictionariesManage', 'instanceSettingsManageCompany'],
        operator: 'or',
      },
    },
    {
      name: 'instance-cards',
      exact: false,
      path: '/settings/instance/cards',
      component: CorporateCards,
      layout: DefaultLayout,
      parent: 'instance-settings',
      showInMenu: true,
      label: 'instance-settings.pay-cards',
      icon: 'credit_card',
      abilities: {
        ability: ['isInstanceAdmin', 'instanceSettingsManageCompanyCards'],
        operator: 'or',
      },
    },
    {
      name: 'additional',
      exact: false,
      path: '/settings/instance/additional',
      component: InstanceSettingsPage,
      layout: DefaultLayout,
      label: 'main-menu.additional',
      parent: 'instance-settings',
      showInMenu: true,
      icon: 'settings',
      abilities: {
        ability: ['clearInstance'],
      },
    },
    {
      name: 'instance-settings',
      exact: false,
      path: '/settings/instance',
      component: ({ history }) => {
        return history.push('/settings/instance/users') || null
      },
      layout: DefaultLayout,
      label: 'instance-settings.instance-settings',
      showInMenu: true,
      icon: 'settings',
      abilities: {
        ability: [
          'isInstanceAdmin',
          'instanceSettingsManageUsers',
          'instanceSettingsManageCompany',
          'instanceSettingsManageCompanyCards',
        ],
        operator: 'or',
      },
    },
    {
      name: 'agent',
      exact: true,
      path: '/requests/agent',
      component: AgentTripRequestsListPage,
      layout: DefaultLayout,
      label: 'main-menu.agent',
      showInMenu: true,
      icon: 'transactions',
      abilities: {
        ability: ['isAgent'],
      },
    },
  ],
}

/**
 *
 * Return element of route array
 *
 * @param scope
 * @param name array
 * @param params
 * @param value
 */
export const getRouteByName = (scope, name, params = {}, value = 'path') => {
  let result = clone(find(routes[scope], { name: name }))

  if (!result) {
    return false
  }

  if (params && value === 'path') {
    forOwn(params, (value, key) => {
      result.path = replace(result.path, `:${key}`, value)
    })
  }

  return result[value]
}
