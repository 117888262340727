import React, { useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { CellClickedEvent } from 'ag-grid-community'
import { Statement } from '../../types/statements'
import { Row } from '../ui/AccordionTable'
import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'

function StatementsPageTable({ data, paginator, navigate, setPage }) {
  const gridOptions = useGridOptions()

  const onCellClicked = useCallback(
    (event: CellClickedEvent<Statement>) => {
      navigate(event.data.id)
    },
    [navigate],
  )

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
        />
      </div>

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  )
}

export default StatementsPageTable
