import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import trans from '../../../trans'
import { RouteManager } from '../../../containers/RouteManager/index'
import { TimelineAdd } from '../../Timeline/index'
import { OtherCostsManager } from '../../../containers/OtherCostsManager/index'
import { OtherCostForm } from '../../../containers/OtherCostForm/index'
import Button from '../../ui/ButtonComponent'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { TripRequestSummary } from '../TripRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { Preview as OtherCostPreview } from '../../OtherCostForm'
import { AddComment } from '../../RequestPageCommon/AddComment'
import { TripTimeline } from '../../TripTimeline/index'
import { Installments } from '../../RequestPageCommon/Installments/Installments'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { TimelineElementsCompliance } from '../TimelineElementsCompliance'
import { get } from 'lodash'
import store, { history } from '../../../store'
import { stopSubmit } from 'redux-form'
import { FORM_NAME as BASIC_INFO_FORM_NAME } from '../../../containers/BasicInfoForm/BasicInfoForm'
import { getRouteByName } from '../../../routes'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import ChronologyConfirmationDialog from '../../RequestAction/ChronologyConfirmationDialog'
import AlertController from '../../../services/alerts-controller'
import { processAPIerrorResponseToFormErrors } from '../../../services/APIClient'
import { AccountDimensionManager } from '../../../containers/AccountDimensionManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class TripRequestPageDraft extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      chronologyConfirmationDialogOpen: false,
    }
  }

  onSend = (e, chronologyConfirmed = false) => {
    const { request, sendToAcceptance } = this.props
    e.preventDefault()

    return sendToAcceptance(chronologyConfirmed, false)
      .then((response) => {
        const autoaccepted = get(response, 'data.autoaccepted', false)
        history.push(getRouteByName('main', `tripRequestsList`))
        if (autoaccepted) {
          history.push(getRouteByName('main', 'tripRequestShow', { id: request.slug }))
        }

        if (response.alerts) {
          AlertController.displayAlerts(response.alerts)
        }
      })
      .catch((response) => {
        if (
          response.alerts &&
          response.alerts[0] &&
          response.alerts[0].message &&
          response.alerts[0].message.name &&
          response.alerts[0].message.name === 'request.request-chronology-warning-confirm-text'
        ) {
          this.setState({ chronologyConfirmationDialogOpen: true })
          store.dispatch(stopSubmit(BASIC_INFO_FORM_NAME, {}))
        } else {
          const errors = processAPIerrorResponseToFormErrors(response.alerts, true)
          store.dispatch(stopSubmit(BASIC_INFO_FORM_NAME, errors))
          if (response) {
            AlertController.displayAlerts(response.alerts)
          }
        }
      })
  }

  onConfirm = (e, confirmed) => {
    if (confirmed === true) {
      this.setState({ chronologyConfirmationDialogOpen: false })
      this.onSend(e, confirmed)
    } else {
      this.setState({ chronologyConfirmationDialogOpen: false })
    }
  }

  render() {
    const {
      request,
      currencies,
      deleteRequest,
      changeRequestValue,
      isModuleActive,
      saveDecision,
      currentUser,
    } = this.props

    return (
      <RouteManager>
        {({ push }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <section className='section'>
              <header className='section__header'>
                <h2 className='h2 section__header-title'>{trans('request.trip-plan')}</h2>
              </header>

              <TripTimeline request={request} currencies={currencies} />
            </section>

            <section className='section'>
              <header className='section__header'>
                <h2 className='h2 section__header-title'>{trans('request.other-costs')}</h2>
              </header>
              <OtherCostsManager request={request}>
                {({ elements, types, onAdd, onEdit, onRemove, isElementOpen, isSaving }) => {
                  return (
                    <div className='timeline-container timeline-container--other-costs has-loader'>
                      {isSaving && <Loader />}
                      {!request['abilities']['edit'] &&
                        elements.map((element, i) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostPreview
                              key={key}
                              form={key}
                              element={element}
                              request={request}
                              types={types}
                              last={i === elements.length - 1}
                            />
                          )
                        })}
                      {request['abilities']['edit'] &&
                        elements.map((element) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostForm
                              key={key}
                              form={key}
                              element={element}
                              onRemove={onRemove}
                              onEdit={onEdit}
                              request={request}
                              currencies={currencies}
                              label=' '
                              types={types}
                              isElementOpen={isElementOpen(element)}
                            />
                          )
                        })}
                      <Ability ability={['edit']}>
                        <TimelineAdd icon='plus' gradient='true' onClick={() => onAdd()} last>
                          {trans('global.add')}
                        </TimelineAdd>
                      </Ability>
                    </div>
                  )
                }}
              </OtherCostsManager>
            </section>

            <TripRequestSummary request={request} />

            <Installments request={request} currentUser={currentUser} />

            <TimelineElementsCompliance />

            <Ability ability={['sendToAcceptance']}>
              <section className='section section--no-border section--no-border-top'>
                <AddComment request={request} changeRequestValue={changeRequestValue} />
              </section>
            </Ability>

            <div className='button-group'>
              <Ability ability={['delete']}>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    deleteRequest().then(() => {
                      push(getRouteByName('main', `tripRequestsList`))
                    })
                  }}
                  link
                >
                  {trans('request.delete-request')}
                </Button>
              </Ability>

              <Ability ability={['edit']}>
                <Button
                  key='save-draft'
                  data-test='save-draft'
                  onClick={(e) => {
                    e.preventDefault()
                    saveDecision(
                      request.comment &&
                        request.comment.content &&
                        request.comment.content.length > 0
                        ? { comment: request.comment }
                        : undefined,
                    ).then(() => {
                      push(getRouteByName('main', `tripRequestsList`))
                    })
                  }}
                  outline
                  pull_end
                >
                  {trans('request.save-draft')}
                </Button>
              </Ability>

              <Ability ability={['sendToAcceptance']}>
                <Button
                  waiting={this.props.ws.isWaitingForWebSocketEvent ? 1 : 0}
                  key='save-send'
                  data-test='save-send'
                  onClick={this.onSend}
                  primary
                  pull_end
                >
                  {trans('request.send')}
                </Button>
              </Ability>
            </div>

            <RequestCommentManager request={request}>
              {({ comments }) => {
                return <RequestComments comments={comments} />
              }}
            </RequestCommentManager>

            <ChronologyConfirmationDialog
              onClose={this.onConfirm}
              open={this.state.chronologyConfirmationDialogOpen}
            />
          </div>
        )}
      </RouteManager>
    )
  }
}

TripRequestPageDraft.propTypes = {
  request: PropTypes.object.isRequired,
  sendToAcceptance: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  changeRequestValue: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  isModuleActive: PropTypes.func.isRequired,
  saveDecision: PropTypes.func.isRequired,
  ws: PropTypes.object,
}

export { TripRequestPageDraft }
export default { TripRequestPageDraft }
