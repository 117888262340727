import React, { Component } from 'react'
import Filters from '../ui/Filters/index'
import trans from '../../trans'
import { DatePickerField, SelectField } from '../ui/Form/index'
import APIClient from '../../services/APIClient'
import withReports from '../../store/app/reports'
import { Loader } from '../ui/LoadingOverlay/Loader'
import moment from 'moment'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'

class ReportsFilters extends Component<any, any> {
  state = {
    MPKs: [],
    companies: [],
    isLoading: true,
  }

  optionAll = {
    label: trans('global.all'),
    value: null,
    onSelectResetsInput: true,
  }

  constructor(props) {
    super(props)

    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect()
  }

  getCompaniesOptions() {
    const { companies } = this.props

    return [this.optionAll, ...companies]
  }

  getExcel(args) {
    args.from = moment(args.from).toString()
    args.to = moment(args.to).toString()

    APIClient.getReportsCockpit(args)
  }

  componentDidMount() {
    const { fetchMpk, fetchCompanies } = this.props

    Promise.all([fetchMpk(), fetchCompanies()]).then((results) => {
      this.setState({ isLoading: false })
    })

    this.props.changeFilters('users', null)
  }

  componentWillUnmount() {
    this.props.changeFilters('users', null)
  }

  render() {
    const { changeFilters, filters } = this.props

    return (
      <Filters.Container>
        <form className='has-loader'>
          {this.state.isLoading && <Loader />}
          <Filters.Row>
            <Filters.Col xs={12} sm={6} md={2} lg={2} title={trans('global.filter-period')}>
              <Filters.FieldContainer className='input-wrapper--date'>
                <DatePickerField
                  placeholder={trans('reports.date-from')}
                  maxDate={{}}
                  onChange={(value) => changeFilters('from', value)}
                  value={filters.from}
                  isClearable={true}
                />
              </Filters.FieldContainer>
            </Filters.Col>
            <Filters.Col xs={12} sm={6} md={2} lg={2} title={trans('global.filter-period')}>
              <Filters.FieldContainer className='input-wrapper--date'>
                <DatePickerField
                  isClearable={true}
                  maxDate={{}}
                  minDate={filters.from}
                  placeholder={trans('reports.date-to')}
                  onChange={(value) => changeFilters('to', value)}
                  value={filters.to}
                />
              </Filters.FieldContainer>
            </Filters.Col>
            <Filters.Col xs={12} sm={6} md={2} lg={2} title={trans('reports.company')}>
              <Filters.FieldContainer>
                <SelectField
                  options={this.getCompaniesOptions()}
                  value={filters.company_id}
                  onChange={(value) => changeFilters('company_id', value)}
                  placeholder={trans('global.filter-placeholder-all')}
                />
              </Filters.FieldContainer>
            </Filters.Col>
            <Filters.Col xs={12} sm={6} md={2} lg={2} title={trans('global.filter-mpk')}>
              <Filters.FieldContainer>
                <AsyncSelectField
                  loadOptions={this.fetchMpkForAsyncSelect}
                  value={filters['mpk']}
                  onChange={(value) => changeFilters('mpk_id', value)}
                  placeholder={trans('global.filter-placeholder-all')}
                  noOptionsMessage={() => {
                    return trans('user.no-results-for-search-message')
                  }}
                  loadingMessage={() => {
                    return trans('user.searching-message')
                  }}
                  delay={500}
                />
              </Filters.FieldContainer>
            </Filters.Col>
            <Filters.Col xs={12} sm={6} md={2} lg={2} title={trans('global.user')}>
              <Filters.FieldContainer>
                <AsyncSelectField
                  loadOptions={loadOptionsForUsers}
                  value={filters['statuses']}
                  onChange={(value) => changeFilters('user_id', value)}
                  placeholder={trans('global.filter-placeholder-everyone')}
                  noOptionsMessage={() => {
                    return trans('user.no-results-for-search-message')
                  }}
                  loadingMessage={() => {
                    return trans('user.searching-message')
                  }}
                  delay={500}
                  components={{ ...components, Option: UserSelectOption }}
                />
              </Filters.FieldContainer>
            </Filters.Col>

            <Filters.Col xs={12} sm={6} md={2} lg={2} title='' className='right-bottom'>
              <a
                href='#'
                onClick={() => this.getExcel(filters)}
                className='btn--primary btn--xs btn btn--inline-block'
              >
                {trans('global.download-report')}
              </a>
            </Filters.Col>
          </Filters.Row>
        </form>
      </Filters.Container>
    )
  }
}

ReportsFilters = withReports()(ReportsFilters)

export default ReportsFilters
export { ReportsFilters }
