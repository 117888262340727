import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { SettlementRequestsManager } from '../../containers/SettlementRequestsManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import PaymentsPageFilters from '../StatementsPageFilters'
import PaymentsPageTable from '../StatementsPageTable'
import useStatements from './hooks/useStatements'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import StatementsPageFilters from '../StatementsPageFilters'
import StatementsPageTable from '../StatementsPageTable'
import Button from '../ui/ButtonComponent'

function StatementsPage() {
  const card = '4239 24** **** 7345'
  const { data, paginator, loading, setPage } = useStatements()

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-settlements')}>
          {trans('main-menu.my-card-statements')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('my-card.statement_header', { card })}
        />

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <StatementsPageFilters />
              {loading && <LoadingOverlay />}
              {!loading && (
                <StatementsPageTable
                  data={data}
                  paginator={paginator}
                  setPage={setPage}
                  navigate={(id) => push(getRouteByName('main', 'my-card-statement', { id }))}
                />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

export default StatementsPage
