import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export const FiltersFieldContainer = ({ children, className }) => {
  const classes = classnames({
    'filter__input-wrapper': true,
    'input-wrapper': true,
    [className]: true,
  })

  return <section className={classes}>{children}</section>
}

FiltersFieldContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
