import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import PaymentsPageFilters from '../PaymentsPageFilters'
import PaymentsPageTable from '../PaymentsPageTable'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import Button from '../ui/ButtonComponent'
import useStatement from './hooks/useStatement'
import StatementSummary from './StatementSummary'
import useTransactions from '../PaymentsPage/hooks/useTransactions'

function StatementPage() {
  const id = 'P2700003'
  const card = '4239 **** **** 7345'
  const { data: transactions, paginator, loading, setPage, load } = useTransactions()
  const { summaries } = useStatement(id)

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-statements')}>
          {trans('main-menu.my-card-statements')}
        </Link>
        <Link to={getRouteByName('main', 'my-card-settlement', { id })}>
          {trans('main-menu.my-card-statement', { id })}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('my-card.single_statement_header', { id, card })}
        />

        <PaymentsPageFilters />
        {loading && <LoadingOverlay />}
        {!loading && (
          <>
            <PaymentsPageTable
              data={transactions}
              paginator={paginator}
              setPage={setPage}
              refresh={load}
            />
            <StatementSummary data={summaries} />
          </>
        )}
      </Section>
    </div>
  )
}

export default StatementPage
