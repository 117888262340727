import React, { Component, Fragment } from 'react'
import { trainsBooking } from '../../../store/app/trains-booking/providers/trainsBooking'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { RequestDocuments } from '../../RequestPageCommon/RequestDocuments'
import {
  BOOKING_CANCELED,
  BOOKING_CANCELLATION_FAILED,
  BOOKING_PENDING_CANCELLATION,
  BOOKING_SUCCESS,
  BOOKING_WITHOUT_TICKET,
} from '../../../store/app/trains-booking'
import { get } from 'lodash'
import SelectedOffer from './SelectedOffer'
import Search from './Search'
import EnableReservationEngine from '../../EnableReservationEngine'
import { getDocuments } from '../../../store/app/document-list'
import { isTrainSearchEnabled } from '../../../store/app/trip-request'
import { STATUS_DRAFT } from '../../../constants/request'

class Reservation extends Component<any, any> {
  isReservationSuccess = () => {
    const {
      trainsBooking: {
        selectors: { selectedOffer },
      },
    } = this.props
    const status = get(selectedOffer, 'option.booking', null)
    return status === BOOKING_WITHOUT_TICKET || status === BOOKING_SUCCESS
  }

  renderDocuments = () => {
    const {
      trainsBooking: { element, request },
    } = this.props

    return (
      <RequestDocuments
        element={element}
        documents={get(element, 'documents', [])}
        request={request}
        listName={`${element.type}-${element.id}`}
      />
    )
  }

  render() {
    const { trainsBooking, isSearchFeatureEnabled } = this.props
    const {
      trainsBooking: { element, request },
    } = this.props
    const selectedOffer = trainsBooking.selectors.selectedOffer
    const status = get(selectedOffer, 'booking')
    const viewOnly =
      request.abilities.view && !request.abilities.edit && !request.abilities.bookOffers
    const isInCancellation = status === BOOKING_CANCELED || status === BOOKING_PENDING_CANCELLATION

    if (viewOnly && request.status === STATUS_DRAFT && !selectedOffer) {
      return <Loader />
    }

    if ((selectedOffer && viewOnly) || status === BOOKING_CANCELLATION_FAILED) {
      return (
        <SelectedOffer
          trainsBooking={trainsBooking}
          form={`reservation-${element.type}-${element.id}`}
        />
      )
    }

    if (isInCancellation) {
      return this.renderDocuments()
    }

    if (!get(trainsBooking.request, 'abilities.bookOffers', false)) {
      return this.renderDocuments()
    }

    if (!trainsBooking.selectors.isSearchEnabled) {
      return (
        <Fragment>
          {this.renderDocuments()}
          {isSearchFeatureEnabled && this.props.documents.length === 0 && (
            <EnableReservationEngine action={trainsBooking.actions.backToSearching} />
          )}
        </Fragment>
      )
    }

    if (!trainsBooking.selectors.initialized) {
      return <Loader />
    }

    if (selectedOffer && !this.isReservationSuccess()) {
      return (
        <SelectedOffer
          trainsBooking={trainsBooking}
          form={`reservation-${element.type}-${element.id}`}
        />
      )
    }

    if (selectedOffer && this.isReservationSuccess()) {
      return this.renderDocuments()
    }

    return <Search trainsBooking={trainsBooking} />
  }
}

const mapStateToProps = (state, props) => {
  const element = props.trainsBooking.element
  return {
    documents: getDocuments(`${element.type}-${element.id}`)(state),
    isSearchFeatureEnabled: isTrainSearchEnabled(state),
  }
}

const withTrainsBooking = trainsBooking(true)
const withConnect = connect(mapStateToProps, null)

Reservation = compose(withTrainsBooking, withConnect)(Reservation)

export { Reservation }
export default Reservation
