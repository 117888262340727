import React, { Component, Fragment } from 'react'
import { Row } from '../../../ui/Grid'
import { get, isEmpty } from 'lodash'
import { getRadius, rangeMap, setCoordinates } from '../../../store/app/range-map'
import { hotelsBooking } from '../../../store/app/hotels-booking/providers/hotelsBooking'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { Modal as OfferListDialog } from '../OfferList/Modal'
import Map from '../Map'
import Slider from '../Slider'
import SearchFields from './SearchFields'
import { RequestDocuments } from '../../RequestPageCommon/RequestDocuments'
import SingleOffer from '../SingleOffer'
import EnableReservationEngine from '../../EnableReservationEngine'
import { reduxForm } from 'redux-form/immutable'
import { Factory as ElementFactory } from '../../../models/timeline'
import { fromJS } from 'immutable'
import { resignFromSearch, search, setSearch } from '../../../store/app/hotels-booking/creators'
import ReservationBox from './ReservationBox'
import { getRequestTravelers } from '../../../store/app/request-traveler'
import { getFormValues } from '../../../utils/forms'
import { saveElement, updateElement } from '../../../store/app/trip-timeline'
import { DateSuggester } from '../../../store/app/trip-timeline/services/date-suggester'
import { AccommodationLocationSuggester } from '../../../store/app/trip-timeline/services/accommodation-location-suggester'
import {
  calculateEndDate,
  getDaysOfStay,
} from '../../TripTimeline/Elements/Containers/Accommodation'
import moment from 'moment'
import { getCurrency } from '../../../store/app/instance'
import { isHotelSearchEnabled } from '../../../store/app/trip-request'
import {
  getSelectedOffer,
  isOptionSelected,
  isReservationCancellationFailed,
  selector as hotelsBookingSelector,
} from '../../../store/app/hotels-booking/selectors'
import currencies from '../../../store/app/currencies'
import { getDocuments } from '../../../store/app/document-list'
import { prepareRequestDates } from '../../../utils/prepareRequestDates'
import { STATUS_DRAFT } from '../../../constants/request'

class Reservation extends Component<any, any> {
  componentDidMount() {
    const {
      hotelsBooking: {
        element,
        actions: { setQuery },
      },
      data,
    } = this.props

    setQuery({
      arrival_at: data.arrival_at,
      departure_at: data.departure_at,
      location: data.location,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.rangeMap.selectors.coordinates.lat && this.props.data.location.lat) {
      this.props.rangeMap.actions.setCoordinates({
        lat: this.props.data.location.lat,
        lng: this.props.data.location.long,
      })
    }
  }

  search = (e) => {
    e.preventDefault()
    const {
      hotelsBooking: {
        selectors: { query },
      },
      rangeMap: {
        selectors: { radius },
      },
      data,
      search,
      setSearch,
    } = this.props

    setSearch(true)

    search({ ...query, ...data, range: radius }).then(() => {
      setSearch(false)
    })
  }

  resignFromSearching = (e) => {
    e.preventDefault()

    const {
      hotelsBooking: {
        actions: { resignFromSearch },
      },
    } = this.props
    resignFromSearch()
  }

  onOpen = () => {
    const { hotelsBooking } = this.props
    hotelsBooking.actions.selectOffer(undefined)
    hotelsBooking.selectors.status = 'pending'
  }

  closeResultsList = () => {
    const { hotelsBooking } = this.props
    hotelsBooking.actions.setFilter('others', '')
    hotelsBooking.actions.closeResultsList()
  }

  renderDocuments = (fragment = false) => {
    const {
      hotelsBooking: { element, request },
      listName,
    } = this.props

    return (
      <RequestDocuments
        element={element}
        documents={get(element, 'documents', [])}
        request={request}
        listName={listName}
        fragment={fragment}
      />
    )
  }

  onRoomChanged = () => {
    this.props.dispatch(this.props.clearFields(true, false, ['request_travelers']))
  }

  render() {
    const { hotelsBooking, handleSubmit, hasDocuments, isSearchFeatureEnabled } = this.props
    const { rangeMap, travelers } = this.props
    const isInCancellation = hotelsBooking.selectors.isReservationInCancellation
    const isCancellationFailed = hotelsBooking.selectors.isReservationCancellationFailed
    const viewOnly =
      hotelsBooking.request.abilities.view &&
      !hotelsBooking.request.abilities.edit &&
      !hotelsBooking.request.abilities.bookOffers

    if (
      viewOnly &&
      hotelsBooking.request.status === STATUS_DRAFT &&
      !hotelsBooking.selectors.isOptionSelected
    ) {
      return <Loader />
    }

    if (isInCancellation) {
      return this.renderDocuments()
    }

    if (hotelsBooking.selectors.isOptionSelected && (viewOnly || isCancellationFailed)) {
      return <SingleOffer hotelsBooking={hotelsBooking} reservation={true} />
    }

    if (!get(hotelsBooking.request, 'abilities.bookOffers', false)) {
      return this.renderDocuments()
    }

    if (hotelsBooking.selectors.isOptionSelected && !hotelsBooking.selectors.isReservationSuccess) {
      return <SingleOffer hotelsBooking={hotelsBooking} reservation={true} />
    }

    if (hotelsBooking.selectors.isOptionSelected) {
      return (
        <Row>
          <ReservationBox hotelsBooking={hotelsBooking} />
          {hotelsBooking.selectors.isReservationSuccess && this.renderDocuments(true)}
        </Row>
      )
    }

    if (!hotelsBooking.selectors.isSearchEnabled) {
      return (
        <Fragment>
          {this.renderDocuments()}

          {isSearchFeatureEnabled && !hasDocuments && (
            <EnableReservationEngine action={hotelsBooking.actions.backToSearching} />
          )}
        </Fragment>
      )
    }

    if (!hotelsBooking.selectors.initialized) {
      return <Loader />
    }

    if (hotelsBooking.selectors.isSearching) {
      return <Loader />
    }

    return (
      <Fragment>
        <form onSubmit={handleSubmit}>
          {hotelsBooking.selectors.initialized && rangeMap.selectors.initialized && (
            <div className='accommodation__form-content'>
              <div className='accommodation__inputs-container accommodation__input-wrapper'>
                <SearchFields
                  hotelsBooking={hotelsBooking}
                  rangeMap={rangeMap}
                  onSearch={this.search}
                  onResign={this.resignFromSearching}
                  onFocus={this.props.focus}
                  travelers={travelers}
                  roomChanged={this.onRoomChanged}
                />
              </div>

              <div className='accommodation__map-container accommodation__offers'>
                <Map
                  radius={rangeMap.selectors.radius}
                  onChange={rangeMap.actions.setRange}
                  coordinates={rangeMap.selectors.coordinates}
                />
                <Slider onChange={rangeMap.actions.setRange} value={rangeMap.selectors.radius} />
              </div>
            </div>
          )}
        </form>

        <OfferListDialog
          isOpen={hotelsBooking.selectors.isResultsListOpen}
          onClose={this.closeResultsList}
          onOpen={this.onOpen}
          hotelsBooking={hotelsBooking}
        />
      </Fragment>
    )
  }
}

Reservation.propTypes = {}

const withForm = reduxForm({
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  onChange: (values, dispatch, props) => {
    const {
      change,
      rangeMap: {
        actions: { setCoordinates },
      },
    } = props

    values = prepareRequestDates(values)

    if (values.departure_at < values.arrival_at) {
      dispatch(change('departure_at', values.arrival_at))
    }

    if (
      values.extraServicesChecked === true &&
      values.extraServicesText &&
      values.extraServicesText.length > 0
    ) {
      values.extra_services = values.extraServicesText
    }

    const location = values.location
    if (location) {
      setCoordinates({
        lat: location.lat,
        lng: location.long,
      })
    }

    const element = {
      ...props.hotelsBooking.element,
      ...values,
    }

    const prevElement = {
      ...props.hotelsBooking.element,
    }

    dispatch(props.updateElement({ prevValues: prevElement, nextValues: element, silent: true }))
  },
})

const mapStateToProps = (state, props) => {
  const {
    hotelsBooking: { element, request },
  } = props

  const { key, draft } = element

  const currentValues = getFormValues(key, state)
  const currentAccommodation = !isEmpty(currentValues)
    ? ElementFactory.create(currentValues)
    : element

  const travelers = getRequestTravelers(state)
  const requestTravelers = travelers.map((traveler) => traveler.slug)
  const rooms = travelers.map(() => 1)

  const dateSuggester = new DateSuggester(state, currentAccommodation)
  const locationSuggester = new AccommodationLocationSuggester(state, currentAccommodation)

  const location = draft ? locationSuggester.suggestLocation() : element.getStartLocation()

  const startDate = draft ? dateSuggester.suggestStartDate() : currentAccommodation.getStartDate()
  const endDate = calculateEndDate(state, currentAccommodation, dateSuggester, location)
  const daysOfStay = getDaysOfStay(
    moment(currentValues.arrival_at),
    moment(currentValues.departure_at),
  )
  const instanceCurrency = getCurrency(state)
  const getHotelsBookingField = hotelsBookingSelector(key)(state)
  const listName = `${element.type}-${element.id}`

  return {
    initialValues: fromJS({
      location: location || {},
      uuid: element.uuid,
      breakfast: element.breakfast,
      arrival_at: startDate,
      departure_at: endDate,
      wifi: element.wifi,
      amount: element.amount,
      amount_currency: !draft ? element.amount_currency : getCurrency(state),
      id: element.id,
      type: element.type,
      converted_amount: element.converted_amount,
      calculated_amount_currency: element.calculated_amount_currency,
      isOpen: element.isOpen,
      acceptance_source: element.acceptance_source,
      draft: element.draft,
      virtual: element.virtual,
      standard: element.standard,
      searcher_disabled: element.searcher_disabled,
      request_travelers: element.request_travelers.length
        ? element.request_travelers
        : requestTravelers,
      rooms: element.rooms.length ? element.rooms : rooms,
      extraServicesChecked: element.extraServicesChecked,
      extraServicesText: element.extraServicesText,
      parking: element.parking,
    }),
    form: key,
    request,
    // currencies,
    daysOfStay,
    data: currentValues,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
    searchUuid: getHotelsBookingField('uuid'),
    selectedOffer: getSelectedOffer(key)(state),
    isSearchEnabled: getHotelsBookingField('enabled'),
    searchQuery: currentValues,
    isOptionSelected: isOptionSelected(key)(state),
    radius: getRadius(key)(state),
    hasDocuments: getDocuments(listName)(state).length > 0,
    isSearchFeatureEnabled: isHotelSearchEnabled(state),
    listName,
    travelers,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const {
    hotelsBooking: { element, request },
  } = props
  const accommodation = ElementFactory.create(element)
  return bindActionCreators(
    {
      resignFromSearch: resignFromSearch(accommodation.key)(request, accommodation),
      search: (searchQuery) => search(accommodation.key)(request, searchQuery),
      setSearch: (status) => setSearch(accommodation.key)(status),
      updateElement,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withRangeMap = rangeMap(true)
const withHotelsBooking = hotelsBooking(true)
const withCurrencies = currencies(false)

Reservation = compose(
  withCurrencies,
  withRangeMap,
  withConnect,
  withForm,
  withHotelsBooking,
)(Reservation)

export default Reservation
export { Reservation }
