import PropTypes from 'prop-types'
import React from 'react'

export const FiltersContainer = ({ children }) => (
  <section className='filters-container'>{children}</section>
)

FiltersContainer.propTypes = {
  children: PropTypes.node,
}
