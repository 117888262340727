import { connect } from 'react-redux'
import React from 'react'
import APIClient from '../../../services/APIClient'
import moment from 'moment'
import { fetchMpk, getMpkSelectOptions } from '../mpks'
import { fetchCompanies, getCompaniesForSelectOptionsSelector } from '../companies/slice'

const romanMonths = ['0', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII']

const getInitialState = () => {
  return {
    filters: {
      mpk_id: null,
      from: moment().subtract(3, 'months').startOf('month'),
      to: moment(),
      company_id: null,
      user_id: null,
    },
    data: {
      monthlyTripsExpenses: {
        months: [],
        expenses: [],
        events: [],
      },
      monthlyExpensesOnExpenseRequests: {
        expenses: [],
        events: [],
      },
      averageExpensesOnNationalAndAbroadTrips: {
        country: '',
        abroad: '',
        currency: '',
        unit: '',
      },
      averageDurationOnNationalAndAbroadTrips: {
        country: '',
        abroad: '',
        unit: '',
      },
      averageExpenseOnNationalAndAbroadAccommodations: {
        country: '',
        abroad: '',
        currency: '',
        unit: '',
      },
      averageExpensesOnNationalAndAbroadPlaneTrips: {
        country: '',
        abroad: '',
        currency: '',
        unit: '',
      },
      hotelStays: {
        preferred: 79,
        other: 21,
      },
      reservations: {
        obt: 348,
        external: 675,
      },
      trips: {
        country: 7635,
        abroad: 2153,
      },
      meetings: {
        preferred: 69,
        other: 509,
      },
      averageExpense: {
        unit: 'PLN',
        value: 0,
      },
      percentageValueInternalAndExternalMeetings: {
        internal: 0,
        external: 0,
      },
      percentageValueNationalAndAbroadTrips: {
        domestic: 0,
        foreign: 0,
      },
      percentageValueRequestsWithBrokenRules: {
        value: 0,
      },
      tripKPI: [],
      expenseKPI: [],
      tripRanking: [],
      expenseRanking: [],
    },
    isReady: false,
  }
}

export const SET_FILTER = 'SET_FILTER'
export const SET_DATA = 'SET_DATA'
export const MOUNT_POINT = 'reports'

export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filter]: payload.value,
        },
        isReady: false,
      }
    case SET_DATA:
      const monthlyTripsExpenses = Object.values(payload.monthlyTripsExpenses)
      const monthlyExpensesOnExpenseRequests = Object.values(
        payload.monthlyExpensesOnExpenseRequests,
      ).map(({ value }) => ({ value }))

      return {
        ...state,
        data: {
          ...state.data,
          monthlyTripsExpenses: {
            months: Object.keys(payload.monthlyTripsExpenses).map(
              (date) => romanMonths[moment(date).month() + 1],
            ),
            expenses: monthlyTripsExpenses.map((trip) => trip.value),
            events: monthlyTripsExpenses.map((trip) => trip.quantity),
          },
          monthlyExpensesOnExpenseRequests: {
            months: Object.keys(payload.monthlyExpensesOnExpenseRequests).map(
              (date) => romanMonths[moment(date).month() + 1],
            ),
            expenses: monthlyExpensesOnExpenseRequests.map((trip) => trip.value),
            events: monthlyExpensesOnExpenseRequests.map((trip) => trip.quantity),
          },
          averageExpensesOnNationalAndAbroadTrips: {
            country: payload.averageExpensesOnNationalAndAbroadTrips.national.value,
            abroad: payload.averageExpensesOnNationalAndAbroadTrips.abroad.value,
            unit: payload.averageExpensesOnNationalAndAbroadTrips.abroad.unit,
          },
          averageExpenseOnNationalAndAbroadAccommodations: {
            country: payload.averageExpenseOnNationalAndAbroadAccommodations.national.value,
            abroad: payload.averageExpenseOnNationalAndAbroadAccommodations.abroad.value,
            unit: payload.averageExpenseOnNationalAndAbroadAccommodations.abroad.unit,
          },
          averageExpensesOnNationalAndAbroadPlaneTrips: {
            country: payload.averageExpensesOnNationalAndAbroadPlaneTrips.national.value,
            abroad: payload.averageExpensesOnNationalAndAbroadPlaneTrips.abroad.value,
            unit: payload.averageExpensesOnNationalAndAbroadPlaneTrips.abroad.unit,
          },
          averageDurationOnNationalAndAbroadTrips: {
            country: payload.averageDurationOnNationalAndAbroadTrips.national.value,
            abroad: payload.averageDurationOnNationalAndAbroadTrips.abroad.value,
            unit: payload.averageDurationOnNationalAndAbroadTrips.abroad.unit,
          },
          percentageValueInternalAndExternalMeetings: {
            internal: payload.percentageValueInternalAndExternalMeetings.internal.value,
            external: payload.percentageValueInternalAndExternalMeetings.external.value,
          },
          percentageValueNationalAndAbroadTrips: {
            domestic: payload.percentageValueNationalAndAbroadTrips.national.value,
            foreign: payload.percentageValueNationalAndAbroadTrips.abroad.value,
          },
          percentageValueRequestsWithBrokenRules: {
            value: payload.percentageValueRequestsWithBrokenRules.value,
          },
          averageExpense: payload.averageExpenseOnExpenseRequests,
          tripRanking: payload.usersRankingInTrips,
          expenseRanking: payload.usersRankingInExpenses,
          tripKPI: Object.values(payload.tripKPI),
          expenseKPI: Object.values(payload.expenseKPI),
        },
        isReady: true,
      }
    default:
      return state
  }
}

export const setReportsFilter = (filter, value) => (dispatch, state) => {
  dispatch({
    type: SET_FILTER,
    payload: { filter, value },
  })
}

export const changeFilters = (filter, value) => (dispatch, state) => {
  dispatch(setReportsFilter(filter, value))
  let reports = getState(state())

  const isEndBeforeStart =
    moment(reports.filters.to).isValid() &&
    !moment(reports.filters.to).isAfter(reports.filters.from)

  if (filter === 'from' && isEndBeforeStart) {
    dispatch(setReportsFilter('to', reports.filters.from))
  }

  reports = getState(state())
  dispatch(fetchReports(reports.filters))
}

export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getFilters = (state) => getState(state).filters
export const getData = (state) => getState(state).data
export const getStatus = (state) => getState(state).isReady

const fetchReports = (filters) => (dispatch) => {
  APIClient.financialDashboardData(filters).then(({ data }) => {
    dispatch({ type: SET_DATA, payload: data })
  })
}

const withReports = () => (Component) => {
  const ReportsFilters = (props) => {
    return <Component<any, any> {...props} />
  }

  const mapStateToProps = (state) => {
    return {
      filters: getFilters(state),
      data: getData(state),
      isReady: getStatus(state),
      mpks: getMpkSelectOptions(state),
      companies: getCompaniesForSelectOptionsSelector(state),
    }
  }

  const mapDispatchToProps = {
    setReportsFilter,
    fetchReports,
    changeFilters,
    fetchMpk,
    fetchCompanies,
  }

  return connect(mapStateToProps, mapDispatchToProps)(ReportsFilters)
}

export default withReports
export { withReports }
